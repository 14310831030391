// Icons
import '@fortawesome/fontawesome-free/css/all.css'
import { aliases, fa } from 'vuetify/iconsets/fa'

// Styles
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa
    }
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      dark: {
        colors: {
          background: "#181a1e",
          primary: "#7380ec",
          secondary: "#7d8da1",
          separator: "#d4d4d4",
          warning: "#FFB400",
          "on-warning": "#FFFFFF",
          surface: "#202528",
          'on-surface': '#E7E3FC',
          "color-light": "#00000066",
          "color-dark": "#edeffd",
          "blue-color": "19226D",
        }
      },
      light: {
        colors: {
          background: "#F6F4FE",
          "blue-color": "19226D",
          primary: "#7380ec",
          secondary: "#7d8da1",
          separator: "#d4d4d4",
          warning: "#FFB400",
          "on-warning": "#FFFFFF",
          surface: "#FFFFFF",
          'on-surface': '#3A3541',
          "color-light": "#848bc8",
          "color-dark": "#302B2B"
        }
      }
    }
  }
})