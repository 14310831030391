import { defineStore } from "pinia";
import { IAuth } from "../helpers/entity/auth.entity";
import { IResponse } from "@/modules/dashboard/helpers/entity/response.entity";
import { AxiosError } from "axios";
import back from "../../../api/back";
import { IUser, IChangePass } from "../../dashboard/helpers/entity/user.entity";
import { IPermission } from "../../dashboard/helpers/entity/role.entity";

interface AuthState {
  token: string,
  logUser: IUser,
  permissions: IPermission[]
}

export const useAuthStore = defineStore('authStore', {
  state: (): AuthState => {
    return {
      token: '',
      logUser: {
        createdAt: '',
        document: '',
        email: '',
        lastName: '',
        name: '',
        password: '',
        phone: '',
        rol: ''
      },
      permissions: []
    } 
  },

  getters: {
    getLogUser: (state) => state.logUser,
    getPermission: (state) => state.permissions
  },

  actions: {
    async loginUser(auth: IAuth): Promise<IResponse> {
      try {
        const { data } = await back.post("/auth", auth)

        this.token = data.token
        localStorage.setItem('token', data.token)
        return {
          data: [],
          error: false,
          message: '',
          status: 200
        }
      } catch (error) {
        if (error instanceof AxiosError){
          if (error.code == 'ERR_NETWORK'){
            throw ({
              message: "No hay conexión con el servidor"
            })
          }else{
            throw ({ 
              message: error.response?.data.errors[0].msg,
              status: error.status 
            })
          }
        }else{
          console.log(error);
          throw ("Error no controlado");
        }        
      }
    },

    async validateToken(): Promise<IResponse> {
      try {
        const { data } = await back.get("/auth/validate-token")

        this.logUser = data.user
        this.permissions = data.permissions
        return { 
          error: false,
          data: [],
          message: '',
          status: 200
        }
      } catch (error) {
        if (error instanceof AxiosError){
          if (error.code == 'ERR_NETWORK'){
            throw ({
              message: "No hay conexión con el servidor"
            })
          }else{
            throw ({ 
              message: error.response?.data.errors[0].msg,
              status: error.status 
            })
          }
        }else{
          console.log(error);
          throw ("Error no controlado");
        }  
      }
    },

    loguot() {
      this.logUser = {
        createdAt: '',
        document: '',
        email: '',
        lastName: '',
        name: '',
        password: '',
        phone: '',
        rol: ''
      }

      this.token = ''
      this.permissions = []
      localStorage.removeItem('token')
    },

    async changePassword(newPass : IChangePass): Promise<IResponse> {
      try {
        const { data } = await back.put("/auth/changePassword", newPass)

        return {
          data: [],
          error: false,
          message: data.message,
          status: 200
        }        
      } catch (error) {
        if (error instanceof AxiosError){
          if (error.code == 'ERR_NETWORK'){
            throw ({
              message: "No hay conexión con el servidor"
            })
          }else{
            throw ({ 
              message: error.response?.data.errors[0].msg,
              status: error.status 
            })
          }
        }else{
          console.log(error);
          throw ("Error no controlado");
        }            
      }
    }
  }
})