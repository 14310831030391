import { AxiosError } from "axios";
import { defineStore } from "pinia";
import { IQuote } from "@/modules/home/helpers/entity/quote.entity";
import { IResponse } from "../../dashboard/helpers/entity/response.entity";
import back from "../../../api/back";

interface QuoteState {
  quote: IQuote,
  result: {
    TarificarVehiculosResult: string
  }
}

export const useQuoteStore = defineStore('quoteStore', {
  state: (): QuoteState => {
    return {
      quote: {
        identificacion : "",
        genero : "",
        nacimiento : "",
        fasecolda : "",
        modelo : "",
        codciudad : "",
        experiencia : "",
        cerokilometros : "",
        siniestro : "",
        accesorios : "",
        apellido1 : "",
        apellido2 : "",
        nombres : "",
        placa : "",
        nacionalidad : "",
        paisnacimiento : "",
        ocupacion : "",
        nivelEducativo : "",
        estrato : ""
      },
      result: {
         TarificarVehiculosResult: ''
      }
    }
  },

  getters: {
    getQuote: (state) => state.quote,
    getResult: (state) => state.result
  },

  actions: {
    async createQuote(quote: IQuote): Promise<IResponse>{
      try {
        const { data } = await back.post("/quotes", quote)

        this.quote = data.quote
        this.result = data.result

        return {
          data: [],
          error: false,
          message: data.message,
          status: 200
        }
      } catch (error) {
        if (error instanceof AxiosError){
          if (error.code == 'ERR_NETWORK'){
            throw ({
              message: "No hay conexión con el servidor"
            })
          }else{
            throw ({ 
              message: error.response?.data.errors[0].msg,
              status: error.status 
            })
          }
        }else{
          throw new Error("Error no controlado");
        }
      }
    }
  }
})