import { RouteRecordRaw } from "vue-router";
import { useAuthStore } from "@/modules/auth/store/useAuthStore";

const routes: RouteRecordRaw = {
  path: '/dashboard',
  name: 'dashboard',
  component: () => import('@/modules/dashboard/layouts/DashboardLayout.vue'),
  beforeEnter: async(to, from, next) => {
    const { validateToken } = useAuthStore()

    try {
      await validateToken()
      next()
    } catch (error) {
      next({ name: 'login' })
    }
  },
  children: [
    {
      path: '',
      name: 'main',
      component: () => import('@/modules/dashboard/views/HomeView.vue')
    },
    {
      path: 'users',
      name: 'users',
      beforeEnter: (to, from, next) => {
        const { getPermission } = useAuthStore()
        const ok = getPermission.find(permission => permission.name == 'users' && permission.checkbox.includes("Ver"))

        ok  
          ? next()
          : next({ name: 'not-authorized' })
      },      
      component: () => import('@/modules/dashboard/views/UserView.vue')
    },
    {
      path: 'roles',
      name: 'roles',
      beforeEnter: (to, from, next) => {
        const { getPermission } = useAuthStore()
        const ok = getPermission.find(permission => permission.name == 'roles' && permission.checkbox.includes("Ver"))

        ok
          ? next()
          : next({ name: 'not-authorized' })
      },      
      component: () => import('@/modules/dashboard/views/RoleView.vue')
    },
    {
      path: 'portfolios',
      name: 'portfolios',
      beforeEnter: (to, from, next) => {
        const { getPermission } = useAuthStore()
        const ok = getPermission.find(permission => permission.name == 'portfolios' && permission.checkbox.includes("Ver"))

        ok
          ? next()
          : next({ name: 'not-authorized' })
      },      
      component: () => import('@/modules/dashboard/views/PortfolioView.vue')
    },
    {
      path: 'quotes',
      name: 'quotes',
      beforeEnter: (to, from, next) => {
        const { getPermission } = useAuthStore()
        const ok = getPermission.find(permission => permission.name == 'quotes' && permission.checkbox.includes("Ver"))

        ok
          ? next()
          : next({ name: 'not-authorized' })
      },
      component: () => import('@/modules/dashboard/views/QuotesView.vue')
    },
    {
      path: 'profile',
      name: 'profile',
      component: () => import('@/modules/dashboard/views/ProfileView.vue')
    },
    {
      path: 'message',
      name: 'message',
      beforeEnter: (to, from, next) => {
        const { getPermission } = useAuthStore()
        const ok = getPermission.find(permission => permission.name == 'messages' && permission.checkbox.includes("Ver"))

        ok
          ? next()
          : next({ name: 'not-authorized' })
      },      
      component: () => import('@/modules/dashboard/views/MessageView.vue')
    },
    {
      path: 'insurances',
      name: 'insurances',
      beforeEnter: (to, from, next) => {
        const { getPermission } = useAuthStore()
        const ok = getPermission.find(permission => permission.name == 'insurances' && permission.checkbox.includes("Ver"))

        ok
          ? next()
          : next({ name: 'not-authorized' })
      },      
      component: () => import('@/modules/dashboard/views/InsuranceView.vue')
    },
    {
      path: 'method-payments',
      name: 'method-payments',
      beforeEnter: (to, from, next) => {
        const { getPermission } = useAuthStore()
        const ok = getPermission.find(permission => permission.name == 'method-payments' && permission.checkbox.includes("Ver"))

        ok
          ? next()
          : next({ name: 'not-authorized' })
      },      
      component: () => import('@/modules/dashboard/views/MethodPaymentsView.vue')
    }
  ]
}

export default routes