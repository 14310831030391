import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { createPinia } from 'pinia'
import PerfectScrollbar from "vue3-perfect-scrollbar";
import moment from 'moment';
import VueDatePicker from '@vuepic/vue-datepicker';
import AOS from "aos";

import 'moment/locale/es';
import 'moment-timezone';

moment.locale('es');
moment.tz.setDefault('America/Bogota');

import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'aos/dist/aos.css'

import './styles/main.css'

// Cargar fuentes
loadFonts()

// Iniciar AOS
AOS.init()

createApp(App)
  .use(createPinia())
  .component('VueDatePicker', VueDatePicker)
  .use(PerfectScrollbar)
  .use(router)
  .use(vuetify)
  .mount('#app')
