import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import routerDashboard from "@/modules/dashboard/router";
import routerHome from '@/modules/home/router';
import routerAuth from '../modules/auth/router';

const routes: Array<RouteRecordRaw> = [
  routerAuth,
  routerDashboard,
  routerHome,
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/NotAuthorized.vue')
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: () => import('@/views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.addRoute({
  path: '/:catchAll(.*)',
  redirect: '/not-found'
})

export default router
