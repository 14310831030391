import { RouteRecordRaw } from "vue-router";
import { useQuoteStore } from "../store/useQuoteStore";

const routes: RouteRecordRaw = {
  path: "/",
  name: "home",
  component: () => import("@/modules/home/layouts/HomeLayout.vue"),
  children: [
    {
      path: "",
      name: "index",
      component: () => import("@/modules/home/views/HomeView.vue")
    },
    {
      path: "about-us",
      name: "about-us",
      component: () => import("@/modules/home/views/AboutUs.vue")
    },
    {
      path: "contact",
      name: "contact",
      component: () => import("@/modules/home/views/ContactUs.vue")
    },
    {
      path: "customer-support",
      name: "customer-support",
      component: () => import("@/modules/home/views/CustomerSupportView.vue")
    },
    {
      path: "terms-conditions",
      name: "terms-conditions",
      component: () => import("@/modules/home/views/TermsConditionsView.vue")
    },
    {
      path: "authorization-personal-data",
      name: "authorization-personal-data",
      component: () => import("@/modules/home/views/AuthorizationPersonalDataView.vue")
    },
    {
      path: "quote-client",
      name: "quote-client",
      beforeEnter: (to, from, next) => {
        const store = useQuoteStore()
        Object.keys(store.getResult).length == 0
          ? next({ name: 'index' })
          : next()
      },
      component: () => import("@/modules/home/views/QuotesView.vue")
    }
  ]
}

export default routes